<template>
  <v-card
    flat
    :outlined="outlined"
    :max-width="maxWidth"
    :min-width="minWidth"
    :max-height="maxHeight"
    :min-height="minHeight"
    class="m-city-select"
    :class="{ 'disabled': disabled }"
  >
    <header class="d-flex align-center">
      <v-autocomplete
        v-if="searchable"
        v-show="!isInternal"
        v-model="controller.search.selected"
        :search-input.sync="controller.search.value"
        :items="list"
        :filter="searchFilter"
        item-value="id"
        item-text="title"
        :mandatory="mandatory"
        :disabled="disabled||isInternal"
        label="Selecionar cidades..."
        :prepend-icon="icons.search"
        append-icon=""
        solo
        flat
        auto-select-first
        hide-no-data
        hide-details
        class="cities-search pl-4 py-1"
      >
        <template v-slot:item="data">
          <span class="grey--text text--darken-2 text-subtitle-2">
            {{ data.item.title }}
          </span>
        </template>
      </v-autocomplete>
      <v-tooltip 
        top
        open-delay="250"
        transition="fade-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="weightable&&hasMultiple&&!isInternal"
            icon
            color="primary"
            class="mr-2"
            v-bind="attrs"
            v-on="on"
            @click="autoWeight(controller.value)"
          >
            <v-icon dense>{{ icons.balance }}</v-icon>
          </v-btn>
        </template>
        Rebalancear 
      </v-tooltip>
    </header>
    <v-divider v-if="searchable" />
    <v-list
      :max-height="maxHeight - 96"
      class="scrollable overflow-y-auto py-0"
    >
      <v-list-item
        v-for="city in controller.value"
        :key="'city-'+city.id"
        :ref="'city-'+city.id"
        :disabled="disabled"
        :inactive="!clickable"
        :ripple="clickable"
        class="city px-3 my-1"
        @click="click(null, city.id)"
      >
        <div 
          v-if="weightable"
          :class="{ 'active': controller.dragger.id==city.id&&controller.dragger.active }"
          class="city-weight-bar"
          @mousemove="(e) => dragMove(city.id, e)"
        >
          <v-progress-linear
            :value="city.weight"
            height="100%"
            class="city-weight-progress"
          />
          <div 
            v-show="!city.locked&&hasMultiple"
            class="city-weight-handle"
            :style="{ 'left': city.weight+'%' }"
            @mousedown="(e) => dragStart(city.id, e)"
            @mouseup="dragEnd"
            @click.stop.prevent
          >
            <div class="city-weight-handle-bar primary" />
          </div>
        </div>
        <v-list-item-action
          v-if="selectable"
          class="mr-4 my-0"
        >
          <v-btn 
            icon
            :disabled="disabled"
            color="primary"
            @click.stop="click(false, city.id)"
          >
            <v-icon dense>{{ icons.checked }}</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title 
            class="primary--text text-subtitle-2"
          >
            {{ city.title }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action
          v-if="weightable&&hasMultiple&&!isInternal"
          class="city-weight my-2"
        >
          <v-text-field 
            v-model="city.weight"
            dense
            flat
            solo
            hide-details
            type="number"
            suffix="%"
            height="24"
            class="city-weight-text"
            @keydown.up.prevent="setWeight(city.id, parseInt(city.weight+1))"
            @keydown.down.prevent="setWeight(city.id, parseInt(city.weight-1))"
            @input="setWeight(city.id, city.weight)"
            @click.stop
          >
            <template v-slot:append-outer>
              <v-btn
                icon
                small
                :color="city.locked ? 'primary' : 'grey'"
                class="ml-1"
                @click="toggleLock(city.id)"
              >
                <v-icon small>{{ icons.lock }}</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-list-item-action>
        <v-list-item-action 
          v-if="clickable"
          class="my-0"
        >
          <v-btn
            icon
            dense
            color="grey"
            class="ml-1"
          >
            <v-icon dense>{{ icons.right }}</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-list-item
        v-for="city in list"
        :key="'city-option-'+city.id"
        :ref="'city-option-'+city.id"
        :disabled="disabled"
        class="city px-3 my-1"
        @click="click(true, city.id)"
      >
        <v-list-item-action
          v-if="selectable"
          class="mr-4 my-0"
        >
          <v-btn 
            icon
            :disabled="disabled"
            color="grey"
          >
            <v-icon dense>{{ icons.unchecked }}</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action 
          v-else-if="clickable"
          class="mr-4 my-0"
        >
          <v-btn
            icon
            dense
            color="grey"
          >
            <v-icon dense>{{ icons.right }}</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title 
            class="grey--text text--darken-2 text-subtitle-2"
          >
            {{ city.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<style lang="scss">

.m-city-select {
  overflow: hidden;
}
.m-city-select.disabled {
  opacity: .8;
  pointer-events: none;
}

.m-city-select .m-select-option {
  width: 100%;
  min-width: 144px;
  flex-basis: 0 !important;
}
.m-city-select .m-select-option.disabled {
  opacity: .4;
  pointer-events: none;
}

.m-city-select .option-content:not(:last-child) {
  border-right: 1px solid var(--light-border);
}

.m-city-select .active .text {
  font-weight: 700 !important;
}

.m-city-select .city {
  position: relative;
}

.m-city-select .city-weight {
  max-width: 96px;
  opacity: .8;
}

.m-city-select .city-weight .city-weight-text .v-input__slot {
  padding: 0 !important;
}
.m-city-select .city-weight .city-weight-text .v-input__append-outer {
  margin: 0 !important;
}
.m-city-select .city-weight .city-weight-text .v-input__control {
  min-height: 24px !important;
}
.m-city-select .city-weight .city-weight-text input {
  font-size: 0.875rem !important;
  font-weight: 500;
  letter-spacing: 0.0125em !important;
  text-align: right;
  padding-top: 4px;
}

.m-city-select .city-weight-bar.active {
  z-index: 2;
}
.m-city-select .city-weight-bar, .m-city-select .city-weight-handle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.m-city-select .city-weight-bar.active .city-weight-progress {
  opacity: .24;
}
.m-city-select .city-weight-progress {
  opacity: .12;
  transition: none;
}

.m-city-select .city-weight-handle {
  height: 100%;
  right: auto;
  margin-left: -9px;
  opacity: 0;
  cursor: col-resize;
}
.m-city-select .city-weight-handle:hover, .m-city-select .city-weight-bar.active .city-weight-handle {
  opacity: .8;
}
.m-city-select .city-weight-bar.active .city-weight-handle  {
  transform: scaleX(200%);
}
.m-city-select .city-weight-handle-bar {
  height: 100%;
  width: 2px;
  margin: 0 8px;
}

.m-city-select input::-webkit-outer-spin-button,
.m-city-select input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.m-city-select input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}


</style>

<script>
  import { mdiMagnify, mdiCheckboxMarked, mdiCheckboxBlankOutline, mdiLock, mdiTune, mdiChevronRight } from '@mdi/js';

  export default {
    props: {
      selected: {
        type: [Object],
        default: () => null
      },
      cities: {
        type: [Array, Object],
        default: () => []
      },
      weightable: {
        type: Boolean,
        default: false
      },
      roundWeights: {
        type: Number,
        default: 1
      },
      clickable: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      },
      mandatory: {
        type: Boolean,
        default: true
      },
      searchable: {
        type: Boolean,
        default: false
      },
      selectable: {
        type: Boolean,
        default: false
      },
      outlined: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      maxWidth: {
        type: [String, Number],
        default: undefined
      },
      minWidth: {
        type: [String, Number],
        default: undefined
      },
      maxHeight: {
        type: [String, Number],
        default: undefined
      },
      minHeight: {
        type: [String, Number],
        default: undefined
      }
    },
    
    data: () => ({
      icons: {
        search: mdiMagnify,
        checked: mdiCheckboxMarked, 
        unchecked: mdiCheckboxBlankOutline,
        lock: mdiLock,
        balance: mdiTune,
        right: mdiChevronRight
      },
      controller: {
        value: null,
        search: {
          value: '',
          selected: null,
        },
        dragger: {
          id: null,
          active: false,
          debounce: null,
        },
      }
    }),

    watch: {
      selected: {
        immediate: true,
        handler (selected) {
          this.controller.value = _.isNil(selected) ? {} : _.clone(selected);
          this.$nextTick(() => {
            console.log('clear');
            this.controller.search.selected = null;
            this.controller.search.value = '';
            if (!this.weightable&&!this.isEmpty&&_.some(selected, city => _.has(city, 'weight'))&&!_.every(selected, ['weight', null])) {
              selected = _.mapValues(_.clone(selected), city => {
                return { ...city, weight: null, locked: false }
              })
              this.update(selected);
            }else if (this.weightable&&!this.isInternal&&!this.isEmpty&&_.some(selected, ['weight', null])) {
              const weight = _.round(100/_.size(selected), this.roundWeights);
              selected = _.mapValues(_.clone(selected), city => {
                return { ...city, weight }
              })
              this.update(selected);
            }
          });
        }
      },
      'controller.search.selected' (id) {
        if (id!=null) {
          console.log(id);
          this.select(true, id);
        }
      },
      weightable (b) {
        if (!b) {
          let selected = _.clone(this.selected);
          selected = _.mapValues(this.selected, city => {
            return { ...city, weight: null }
          })
          this.update(selected);
        }
      }
    },

    computed: {
      list () {
          const internal = this.isInternal;
          const options = _.orderBy(_.values(_.omit(this.cities, _.keys(this.selected))), ['title'], ['asc'])
          return internal ? [] : options;
      },
      hasMultiple () {
        return _.size(this.selected)>1;
      },
      isEmpty () {
        return _.size(this.selected)==0;
      },
      isInternal () {
        return _.has(this.selected, 0)
      },
    },

    methods: {
      select (b, id) {
        let selected = _.clone(this.controller.value);
        let size = _.size(selected);
        let city;
        let weight;
        let params;
        if (b) {
          city = _.clone(this.cities[id]);
          if (id==0||!this.multiple) {
            size = 1;
            selected = { [id]: city };
            if (id!=0) selected[id].weight = 100
            this.locked = [id]
          }else{
            size = _.size(_.omitBy(selected, 'locked'))+1;
            selected[id] = city;
            weight = _.round((100-_.sumBy(_.filter(selected, 'locked'), 'weight'))/size, this.roundWeights)
            selected[id].weight = weight;
            params = [selected, id, weight]
          }
        }else{
          if (this.mandatory&&size==1) {
            this.toggleMessage('Ao menos 1 cidade deve ser selecionada');
            return;
          }
          city = _.clone(this.controller.value[id]);
          weight = -city.weight;
          selected = _.omit(selected, id);
          this.controller.locked = _.pull(this.controller.locked, id)
          size -= 1;
          params = [selected, null, weight]
        }
        if (id!=0&&size&&this.weightable) selected = this.autoWeight(...params);
        this.update(selected)
      },
      update (selected) {
        // console.log('selected cities', selected);
        setTimeout(($) => {
          $.$emit('update', selected);
        }, 250, this);
      },

      setWeight (id, weight, e) {
        if (weight!=''&&weight!=null) {
          let selected = _.clone(this.controller.value);
          const available = 100-_.sumBy(_.filter(selected, 'locked'), 'weight')
          weight = parseFloat(weight);
          if (weight<=0) {
            weight = 0;
          }else if (weight>available-1) {
            weight = available-1;
          }
          const delta = weight - selected[id].weight;
          selected[id].weight = weight;
          selected = this.autoWeight(selected, id, delta);
          this.update(selected);
        }
      },

      autoWeight (selected, changed, delta) {
        let locked = _.concat(_.map(_.pickBy(selected, 'locked'), 'id'), _.isNil(changed) ? [] : [changed])
        if (_.size(locked)==_.size(selected)) locked = [];
        let weightable = _.omit(selected, locked);
        const rebalance = _.isNil(delta);
        const size = _.size(weightable);
        const total = 100-_.sumBy(_.values(_.pick(selected, locked)), 'weight');
        delta = rebalance ? total : delta;
        const slice = delta / size;
        _.reduce(selected, (left, city) => {
          if (_.indexOf(locked, city.id)<0) {
            const d = city.weight-_.round((delta*(city.weight/(total+delta))), this.roundWeights);
            const s = slice;
            const weight = left.size<=1 ? _.ceil(left.total, this.roundWeights) : _.round((rebalance? s : d), this.roundWeights);
            selected[city.id] = {
              ...city, 
              weight
            }
            left.size -= 1;
            left.total -= weight;
          }
          console.log('weight total', total);
          return left;
        }, { total, size: _.size(weightable) });
        return selected;
      },

      toggleLock (id) {
        const city = this.controller.value[id];
        city.locked = !city.locked;
        this.update(_.clone(this.controller.value));
      },

      dragStart (id, e) {
        e.preventDefault();
        this.controller.dragger.id = id;
        this.controller.dragger.active = true;
        this.controller.dragger.start = e.pageX;
      },
      dragMove (id, e) {
        if (this.controller.dragger.active&&this.controller.dragger.id==id) {
          if (this.controller.dragger.debounce==null) {
            const bounds = this.$refs['city-'+id][0].$el.getBoundingClientRect();
            const width = bounds.width;
            const left = bounds.left;
            const weight = _.round(((e.pageX - left)/width)*100);
            if (weight!=this.controller.value[id].weight) {
              this.setWeight(id, weight);
            }
          }else{
            this.controller.dragger.debounce = setTimeout(($) => {
              $.controller.dragger.debounce = null;
            }, 100, this);
          }
        }
      },
      dragEnd () {
        this.controller.dragger.id = null;
        this.controller.dragger.active = false;
        this.controller.dragger.start = null;
      },

      click (b, id) {
        if (this.selectable&&b!=null) {
          console.log(b, id);
          this.select(b, id)
        }
        if (this.clickable&&!(this.selectable&&b!=null)) {
          console.log(b, id);
          this.$emit('click', id);
        }
      },

      toggleMessage (message) {
        this.$emit("message", message);
      },

      searchFilter (item, queryText, itemText) {
        return new RegExp(queryText.normalize("NFD").replace(/\p{Diacritic}/gu, ""), 'gi').test(itemText.normalize("NFD").replace(/\p{Diacritic}/gu, ""));
      }

    },
    mounted () {
      document.addEventListener('touchend', this.dragEnd, false)
      document.addEventListener('mouseup', this.dragEnd)
    },
    beforeDestroy () {
      document.removeEventListener('touchend', this.dragEnd)
      document.removeEventListener('mouseup', this.dragEnd)
    },
  }
</script>